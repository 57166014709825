import { useAuthStore } from '@/store/auth'

export default function (error, title, context) {
  const authStore = useAuthStore()
  const message =
    error.response && error.response.data.message
      ? error.response.data.message
      : error.message

  if (!error.response || !error.response.status) {
    context.$toast.add({
      severity: 'error',
      summary: title,
      detail: 'Gagal terhubung ke server. Harap cek koneksi internet Anda.',
      life: 3000,
    })

    return
  }
  console.log('cek error', error)
  console.log('cek error response', error.response)
  console.log('cek error response status', error.response.status)

  switch (error.response.status) {
    case 400:
      context.$toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
    case 403:
      context.$toast.add({
        severity: 'error',
        summary: title,
        detail: 'Anda tidak memiliki hak untuk mengakses modul ini.',
        life: 3000,
      })
      setTimeout(() => {
        authStore.redirectLogout()
        context.$router.push({ path: '/login' })
      }, 1000)
      break
    case 422:
      if (error.response.data.data || error.response.data.errors) {
        const data =
          error.response.data.data || error.response.data.errors.json.details
        let msg = ''
        for (const [, value] of Object.entries(data)) {
          msg = msg + value
        }
        context.$toast.add({
          severity: 'error',
          summary: title,
          detail: msg,
          life: 3000,
        })
      }
      break
    case 429:
      context.$toast.add({
        severity: 'error',
        summary: title,
        detail: 'Too many requests, please try again later.',
        life: 3000,
      })
      break
    case 401:
      context.$toast.add({
        severity: 'error',
        summary: title,
        detail: 'Token expired, please re-login!',
        life: 3000,
      })
      authStore.redirectLogout()
      context.$router.push({ path: '/login' })
      break
    default:
      context.$toast.add({
        severity: 'error',
        summary: title,
        detail: message,
        life: 3000,
      })
      break
  }
}
